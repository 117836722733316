import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import * as auth0 from "auth0-js";
import { useUserStore } from "@/store/modules/userStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/modules/site/mainLayout.vue"),
    children: [
      {
        path: "",
        name: "notfound",
        component: () => import("@/components/NotFound.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Subscriber",
    component: () => import("@/modules/site/subscriberLayout.vue"),
    children: [
      {
        path: "/subscriber",
        name: "subscriber",
        component: () => import("@/modules/site/pages/streamHub.vue"),
      },
      {
        path: "/subscriber/rewards",
        name: "streamhubRewards",
        component: () => import("@/modules/site/subscriberCabinet/streamhubRewards.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "singleGame",
    component: () => import("@/modules/site/singleGamePageLayout.vue"),
    children: [
      {
        path: "/single-game",
        name: "singleGamePage",
        component: () => import("@/modules/site/pages/singleGamePage.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Site",
    component: () => import("@/modules/site/mainLayout.vue"),
    children: [
      // {
      //   path: "/test",
      //   name: "test",
      //   component: () => import("@/modules/site/pages/testPage.vue"),
      // },
      {
        path: "",
        name: "main",
        component: () => import("@/modules/site/pages/mainPage.vue"),
      },
      {
        path: "/ref/:id",
        name: "ref",
        component: () => import("@/modules/site/pages/mainPage.vue"),
      },
      {
        path: "/game/:provider/:id",
        name: "game",
        component: () => import("@/modules/site/pages/gamePage.vue"),
        props: true,
      },
      {
        path: "/conditions",
        name: "conditions",
        component: () => import("@/modules/site/pages/conditionsPage.vue"),
      },
      {
        path: "/tournaments",
        name: "tournaments",
        component: () => import("@/modules/site/pages/tournamentsPage.vue"),
      },
      {
        path: "/streamers-tournament",
        name: "streamers-tournament",
        component: () =>
          import("@/modules/site/pages/streamersTournamentPage.vue"),
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/modules/site/pages/privacyPolicy.vue"),
      },
      {
        path: "/safety-policy",
        name: "safety-policy",
        component: () => import("@/modules/site/pages/safetyPolicy.vue"),
      },
      {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: () => import("@/modules/site/pages/termsOfUse.vue"),
      },
      {
        path: "/profile/user",
        name: "profile",
        component: () => import("@/modules/site/pages/profilePage.vue"),
      },
    ],
  },
  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: () => import("@/modules/admin/mainLayout.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "AdminLogin",
  //       component: () => import("@/modules/admin/pages/loginPage.vue"),
  //     },
  //     {
  //       path: "languages",
  //       name: "AdminLanguages",
  //       component: () => import("@/modules/admin/pages/languagesPage.vue"),
  //     },
  //     {
  //       path: "tournaments",
  //       name: "AdminTournaments",
  //       component: () => import("@/modules/admin/pages/tournamentsPage.vue"),
  //     },
  //     {
  //       path: "tournaments/form/:id?",
  //       name: "AdminTournamentsForm",
  //       component: () =>
  //         import("@/modules/admin/pages/tournamentsFormPage.vue"),
  //     },
  //     {
  //       path: "tournaments/winners/:id",
  //       name: "AdminTournamentsWinners",
  //       component: () =>
  //         import("@/modules/admin/pages/tournamentsWinnersPage.vue"),
  //     },
  //   ],
  // },
  {
    path: "/redirectOAuth",
    component: () => {
      const auth = new auth0.WebAuth({
        domain: "https://" + window.location.host,
        clientID: "bw8kpsf8y1pmja757uxjj8svpphu9f",
      });
      auth.popup.callback({ hash: window.location.hash });
      setTimeout(window.close, 1000);
    },
  },
  {
    path: "/redirectOAuth/google",
    component: () => {
      const auth = new auth0.WebAuth({
        domain: "https://accounts.google.com/o/oauth2/v2/auth",
        clientID:
          "803993635299-6tsr9rofm43ahk9n7uc5dfql470e43sf.apps.googleusercontent.com",
      });

      auth.popup.callback({ hash: window.location.hash });
      // setTimeout(window.close, 1000);
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  scrollBehavior: (to, from, savedPosition) => {
    if (to.name === "game" && from.name === "game") {
      return { top: 0 };
    }
    return { top: 0, behavior: "instant" };
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  next();
});

export default router;
